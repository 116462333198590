const AllAppRoutes = {
    about_us: "/about-us",
    instructors: "/instructors",
    contact_us: "/contact-us",
    sign_in: "/signin",
    sign_up: "/signup",
    sign_out: "/signout",
    sign_up_verification: "/verify-account",
    verification_successful: "/account-verification/:verificationId",
    our_team: "/ourteam",
    corporate: "/corporate",
    merch: "/merch",
    products: "/products/all",
    product_detail: "/products/detail",
    physical_plans: "/physical-plans/all",
    physical_detail: "/physical/detail",
    sign_up: "/signup",
    profile: "/dashboard",
    appVideos: "/videos",
    help: "/help",
    healthcare: "/healthcare",
    profileVideos: "/profile/videos",
    profileSettings: "/profile/settings",
    playProfileVideo: "/profile/video/play/:videoId/:videoName",
    profileVideoToPlay: "/profile/video",
    videoViewsAnalytics: "/profile/users/videoViewsAnalytics",
    profileProductOrders: "/profile/product_orders",
    profileVideoUploads: "/profile/video/uploads",
    profileLifestylePlans: "/profile/lifestyle",
    profileHealthcarePlans: "/profile/healthcare",
    profilePlanOrders: "/profile/orders",
    profileTransactionHistory: "/profile/transaction-history",
    profileEventTickets: "/profile/reviews",
    profileEventTickets: "/profile/event_tickets",
    profileReviews: "/profile/reviews",
    profileMerchandise: "/profile/merchandise",
    profileVirtualSubscription: "/profile/virtual_subscriptions",
    profileVideoPurchaseSuccess: "/profile/video-purchase-success",
    profileFundWalletSuccess: "/profile/fund-wallet-success",
    profileSubscriptionSuccess: "/profile/user-subscription-success",
}

export default AllAppRoutes;