import React, { useEffect, useState } from "react";
import { Input, Spin, Rate, notification } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { Controller, useForm } from "react-hook-form";
import { _add_video_ratings } from "../utils/axiosroutes";
import { FrownOutlined, MehOutlined, SmileOutlined } from '@ant-design/icons';

import BulbIllustration from "../assets/images/illustrations/11_bulb.png";

const VideoRatings = props => {
    const { handleSubmit, control, formState: { errors }, setValue } = useForm({});
    const [videoRating, setVideoRating] = useState(5);
    const [loadingRatingsBar, setLoadingRatingsBar] = useState(false);

    const antIcon = (<LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />);

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: '',
            description: message
        });
    };

    const customIcons = {
        1: <FrownOutlined />,
        2: <FrownOutlined />,
        3: <MehOutlined />,
        4: <SmileOutlined />,
        5: <SmileOutlined />,
    };

    const videoRatingMessages = [
        "Just finished a fantastic Dancerapy dance fitness session! 💃 Feeling energized, empowered, and ready to conquer the day! Who's joining me for the next one? #Dancerapy #FitnessGoals",
        "Dance your stress away with Dancerapy! 🎶 Just completed an amazing dance workout and feeling refreshed and rejuvenated. If you need a pick-me-up, this is it! #DanceFitness #SelfCare",
        "Can't stop smiling after that Dancerapy session! 😊 Dancing my heart out to great music and feeling fantastic. Who knew exercise could be this much fun? #Dancerapy #FitnessFun",
        "Just had the best dance workout with Dancerapy! 💪 Sweating it out to awesome tunes and loving every minute of it. If you're looking for a fun way to stay active, you've gotta try this! #WorkoutMotivation #Dancerapy",
        "Feeling the positive vibes after a Dancerapy dance fitness session! 💃 Great music, great moves, and a great way to stay healthy and happy. Let's dance our way to better health together! #Dancerapy #HealthyLiving",
        "Another day, another amazing Dancerapy workout! 🎉 Dancing away the stress and boosting my mood like never before. Join me on this journey to fitness and fun! #DanceYourHeartOut #FitnessJourney",
        "Just crushed a Dancerapy dance fitness video and feeling unstoppable! 🔥 Who says working out has to be boring? Not with Dancerapy! Join me in shaking off the negativity and embracing the joy of movement! #FitnessFriday #Dancerapy"
    ];

    const addVideoRatings = async e => {
        setLoadingRatingsBar(true);
        let videoData = {
            videoId: props.videoId,
            rating: videoRating,
            message: e.message
        }
        try {
            let submitVideoRatings = await _add_video_ratings(videoData);
            if (submitVideoRatings) {
                openNotificationWithIcon('success', 'Video ratings added successfully');
                props.closeModal();
                setLoadingRatingsBar(false);
                setVideoRating(3);
                setValue('message', '');
            } else {
                openNotificationWithIcon('error', 'An error occurred while adding video ratings. Please try again');
            }
        } catch (err) {
            console.log(err)
            setLoadingRatingsBar(false);
            openNotificationWithIcon('error', 'An error occurred while adding video ratings. Please try again');
        }
    }
    return (
        <div>
            <div>
                {/* <div className="fund-modal-content">
                    <h3>Rate Video</h3>
                    <p>Invite a friend to Dancerapy and earn 0.25% on all their
                        transactions. This is a total of all the assets you have in your portfolio</p>
                </div> */}
                <form onSubmit={handleSubmit(addVideoRatings)}>
                    <div className="center-text share-bar">
                        <img src={BulbIllustration} alt="share illustration" />
                        <h3>Earn free tokens by sharing video across social media.</h3>
                        <p>You can share this video to your friends and families and get free tokens to pay for subscription when they
                            sign up with your referral code. Click on the social media icons below to quickly share across social media or just
                            copy video url to send to whoever you want.
                        </p>
                        <div className="share-icon-box-cover">
                            <div className="share-icon-box"></div>
                            <div className="share-icon-box"></div>
                            <div className="share-icon-box"></div>
                            <div className="share-icon-box"></div>
                        </div>
                        <div className="share-input-box">
                            <Input name="share-input" disabled
                                value="http://localhost:4000/profile/video/play/feb819ce-2aa3-11ee-b21f-30e171842158/Dance%20Trends%20-%20Yahooze" />
                            <div>
                                <button className="btn-green">Copy</button>
                            </div>
                        </div>
                        {/* <p>{videoRatingMessages[1]}</p> */}
                    </div>
                    {/* <div className="form-group">
                        <label style={{ display: 'block' }}>Ratings</label>
                        <Rate style={{ fontSize: '3rem' }} onChange={setVideoRating}
                            value={videoRating} character={({ index }) => customIcons[index + 1]} />
                    </div>
                    <div className="form-group">
                        <label style={{ display: 'block' }}>Message <small>(optional)</small></label>
                        <Controller name="message" control={control}
                            render={({ field }) => {
                                return (
                                    <Input.TextArea name="message" {...field}
                                        rows={4} />
                                )
                            }} />
                    </div> */}
                    {/* {
                        !loadingRatingsBar ?
                            <button className="btn-red">Add Rating</button>
                            :
                            <button className="btn-red"><Spin indicator={antIcon} /></button>
                    } */}
                </form>
            </div>
        </div>
    )
}

export default VideoRatings;