import React, { useRef, useState } from "react";

import { Modal, Input, notification, Spin } from "antd";
import { connect } from "react-redux";

import ReferImage from "../assets/images/illustrations/11_bulb.png";
import ReferralImage from "../assets/images/illustrations/5_bell.png";
import { _set_user_free_marathon_sub } from "../utils/axiosroutes";
import { useNavigate } from "react-router-dom";

const FreeMonthSubscription = props => {
    const referralMessage = useRef();
    const [referralModal, setReferralModal] = useState(false);

    const Navigate = useNavigate();
    const [displaySpinner, setDisplaySpinner] = useState(false);

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: '',
            description: message
        });
    };

    const getFreeMarathonSubscription = async () => {
        props.loadModalSpinner();
        try {
            let userSub = await _set_user_free_marathon_sub();
            if (userSub.data.statusMessage === "success") {
                openNotificationWithIcon('success', "Subscription successful")
                Navigate(0);
            } else {
                props.hideLoaderSpinner();
                openNotificationWithIcon('error', userSub?.data?.summary);
            }
        } catch (err) {
            props.hideLoaderSpinner();
            openNotificationWithIcon('error', 'An error occurred while completing your subscription. Please try again or reach out to us.');
        }
    }

    return (
        <Spin spinning={displaySpinner}>
            <div>
                <div className="grid-2">
                    <div>
                        <img src={ReferImage} alt="" />
                    </div>
                    <div>
                        <p>Free 1 month subscription</p>
                        <h4>Get free 1 month access to all Dancerapy dance workouts and trending dance styles</h4>
                        <button
                            onClick={getFreeMarathonSubscription}
                            className="btn-default">Get Now</button>
                    </div>
                </div>
            </div>
        </Spin>
    )
}

const mapStateToProps = state => {
    return { auth: state.auth }
}

export default connect(mapStateToProps)(FreeMonthSubscription);